import * as React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import resolveAssetURL from '@/utils/resolveAssetURL'

import CircularLabelButton from '@/components/CircularLabelButton'

import ImageCercle from '@/images/logo-cloudy-bay-circle.svg'

import {
  Container,
  ImageWrapper,
  Image,
  Overlay,
  GridContainer,
  Card,
  Wrapper,
  Content,
  SubTitle,
  Title,
  Text,
  CardMobile,
  CardDesktop,
  TagsWrapper,
  Tag,
  LogoCercle
} from './style'

const Hero = ({
  id,
  title = '',
  subtitle = '',
  text = '',
  actionLabel = '',
  tags = [],
  action = null,
  image,
  children,
  circularButton = [],
  nbLabel = 6,
  shortPage = false,
  wineClub = false
}) => {
  const [isVisitUs, setIsVisitUs] = useState(false)

  const handleClick = () => {
    if (action) {
      action()
    } else {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    setIsVisitUs(window.location.pathname.includes('visit-us'))
  }, [])

  return (
    <Container spaceY={ isVisitUs }>
      <ImageWrapper className={ shortPage ? 'shortPage' : '' }>
        <Image loading='lazy' src={ resolveAssetURL(image) } alt='field' />
        <Overlay />
      </ImageWrapper>
      <GridContainer>
        <Wrapper className={ `${(children || (children && children.length)) && 'children'}` }>
          <Content>
            { tags.length > 0 && (
              <TagsWrapper>
                { tags.map((tag, i) => (
                  <Tag key={ i }>{ tag }</Tag>
                )) }
              </TagsWrapper>
            )}
            { subtitle && <SubTitle>{ subtitle }</SubTitle> }
            { title && <Title>{ title }</Title> }
            { text && <Text>{ text }</Text> }
            { !circularButton.length && (
              <CircularLabelButton
                id={ id || 'textcercle' }
                label={ actionLabel }
                nbLabels={ nbLabel }
                color='white'
                icon='arrow-down'
                onClick={ handleClick }
              />
            )}
            {
              !!circularButton.length && (
                circularButton.map((button) => (
                  button
                ))
              )
            }
          </Content>

          {
            wineClub && (
              <LogoCercle loading='lazy' src={ ImageCercle } alt='logo cercle' />
            )
          }

          { (children || (children && children.length)) && (
            <CardDesktop>
              { children }
            </CardDesktop>
          ) }
        </Wrapper>
      </GridContainer>
      { (children || (children && children.length)) && (
        <CardMobile>
          { children }
        </CardMobile>
      ) }
    </Container>
  )
}

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  actionLabel: PropTypes.string,
  action: PropTypes.func,
  nbLabel: PropTypes.number,
  image: PropTypes.string.isRequired
}

export default Hero
