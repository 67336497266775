import styled from '@emotion/styled'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import { Button as CircularLabelButtonButton } from '@/components/CircularLabelButton/style'
import WysiwygBase from '@/components/Wysiwyg'

import {
  mediaMax,
  mediaMin,
  mediaBetween,
  sectionTitle,
  sectionSubtitle
} from '@/styles/mixins'

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: visible;
  height: 190vh;
  z-index: -1;
  clip: rect(0px, 100vw, max(90vh, var(--min-height)), 0px);

  &.shortPage{
    height: auto;
  }

  ${mediaMin.md} {
    ${mediaMax.lg} {
      height: 100%;
      clip: unset;
    }
  }

  ${mediaMax.xs}{
    clip: rect(0px, 100vw, max(60vh, var(--min-height)), 0px);
  }
`

export const Image = styled.img`
  position: sticky;
  top: 0;
  width: 100%;
  height: max(90vh, var(--min-height));
  object-fit: cover;
  ${mediaMin.md} {
    ${mediaMax.lg} {
      height: max(100vh, var(--min-height));
    }
  }
`

export const LogoCercle = styled.img`
  position: absolute;
  top: 50%;
  right: 10rem;
  transform: translate(0, -50%);
  width: 23rem;
  height: 23rem;
  object-fit: contain;
  z-index: 2;

  ${mediaMax.sm} {
    top: 25%;
    right: 2.1rem;
    width: 13.2rem;
    height: 13.2rem;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.black}50`};
`

export const GridContainer = styled(GridContainerBase)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: max(90vh, var(--min-height));
`

export const Card = styled.div`

  align-self: flex-end;
  width: 60%;

  ${mediaMax.xs} {
    align-self: flex-start;
    width: 90%;
  }

  ${mediaMin.sm} {
    ${mediaMax.md}{
    height: 80vh;
    width: auto;
    }
  }

  ${mediaMin.xl} {
    width: 50%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  ${mediaBetween('md', 'sm')} {
    padding-top: 10rem;
  }

  &.children {
    justify-content: space-between;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 35%;

  ${mediaMax.lg} {
    width: 60%;
  }

  ${mediaMax.xs} {
    width: 90%;
    padding-top: ${({ theme }) => theme.header.normal}px
  }

  ${CircularLabelButtonButton} {
    transform: translateX(25%) scale(1.5);
    padding: 0;
    z-index: 4;
    @media screen and (max-height: 620px) and (max-width: 800px) {
      transform: translateX(25%) scale(1.2);
    }
    @media screen and (max-height: 550px) and (max-width: 800px) {
      transform: translateX(25%) scale(.8);
    }
    ${mediaMax.xxs} {
      transform: translateX(25%) scale(1);
    }
    ${mediaMax.xs} {
      display: none;
    }
  }
`

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

export const SubTitle = styled.h2`
  ${sectionSubtitle};
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  margin-bottom: 1rem;
`

export const Title = styled.h1`
  ${sectionTitle};
  margin-bottom: 3.4rem;
  color: ${({ theme }) => theme.colors.white};
`

export const Text = styled(WysiwygBase)`
  color: ${({ theme }) => `${theme.colors.white}d9`};
  margin-bottom: 5rem;
`

export const CardMobile = styled(Card)`
  width: 90%;
  align-self: center;
  transform: translateY(-7.5%);
  ${mediaMin.md} {
    display: none;
  }
  ${mediaMax.md}{
    transform: none;
    height: fit-content;
    width: 100%;
  }
`
export const CardDesktop = styled(Card)`
  ${mediaMax.md} {
    display: none;
  }
`

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 3rem;
  ${mediaMax.md}{
    margin-bottom: 1.6rem;
  }
`

export const Tag = styled.span`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.white};
  text-transform: none;
  padding: 1rem 1.3rem;
  background-color: ${({ theme }) => theme.colors.green};
  text-transform: uppercase;
  ${mediaMax.md}{
    font-size: 1.2rem;
  }
`

export const Container = styled.div`
  --min-height: ${({ spaceY }) => spaceY ? '92vh' : '820px'};

  min-height: var(--min-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;

  ${mediaBetween('sm', 'lg')}{
    height: 90vh;
    overflow: hidden;
  }

  ${mediaMax.md} {
    --min-height: 90vh;
    flex-direction: column;
    min-height: inherit;
  }
`
